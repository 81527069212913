import Vue from 'vue'
import Router from 'vue-router'
import Head from 'vue-head'
import Home from '@/views/Home'
import CheckLogin from '@/views/CheckLogin'
import { isNil } from 'lodash'
import store from '@/store'

Vue.use(Router)

/* If you don't know about VueHead, please refer to https://github.com/ktquez/vue-head */

Vue.use(Head, {
  complement: process.env.VUE_APP_TITLE
})

/* If you don't know about VueRouter, please refer to https://router.vuejs.org/ */

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/check-login',
      name: 'check-login',
      component: CheckLogin,
      meta: {
        authNotRequired: true
      }
    },
    {
      path: '/login',
      name: 'login',
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-login" */ '@/views/Login.vue'
        ),
      meta: {
        authNotRequired: true
      }
    },
    {
      path: '/passwords',
      name: 'passwords',
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-products" */ '@/views/Passwords.vue'
        )
    },
    {
      path: '/passwords/:category',
      name: 'category',
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-products" */ '@/views/Category.vue'
        )
    },
    {
      path: '/onboarding',
      name: 'onboarding',
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-products" */ '@/views/Onboarding.vue'
        )
    },
    {
      path: '/pin',
      name: 'pin',
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-products" */ '@/views/Pin.vue'
        )
    },
    {
      path: '/add',
      name: 'add',
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-products" */ '@/views/Add.vue'
        )
    },
    {
      path: '/password/:passwordId',
      name: 'password',
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-products" */ '@/views/Password.vue'
        )
    },
    {
      path: '/settings',
      name: 'settings',
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-products" */ '@/views/Settings.vue'
        )
    },
    { path: '*', redirect: '/' }
  ]
})

/**
 * Handle user redirections
 */
// eslint-disable-next-line consistent-return
router.beforeEach((to, from, next) => {
  if (
    !(to.meta && to.meta.authNotRequired) &&
    isNil(store.state.authentication.user)
  ) {
    const path =
      store.state.authentication.user === null ? '/login' : '/check-login'
    return next(`${path}?redirectUrl=${to.path}`)
  }
  next()
})

export default router
