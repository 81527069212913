import router from '@/router'
import { isNil } from 'lodash'
import { createNewUserFromFirebaseAuthUser } from '@/misc/helpers'
import UsersDB from '@/firebase/users-db'
import VaultsDB from '@/firebase/vaults-db'
import { newVault } from '@/misc/crypto'

export default {
  /**
   * Callback fired when user login
   */
  login: async ({ commit, dispatch }, firebaseAuthUser) => {
    const userFromFirebase = await new UsersDB().read(firebaseAuthUser.uid)

    const user = isNil(userFromFirebase)
      ? await createNewUserFromFirebaseAuthUser(firebaseAuthUser)
      : userFromFirebase

    commit('setUser', user)
    if (user.vaultId) {
      dispatch('vaults/fetchVault', user.vaultId || user.vaults[0], {
        root: true
      })
    } else {
      dispatch('vaults/setNullVault', null, { root: true })
    }
  },

  /**
   * Callback fired when user logout
   */
  logout: ({ commit }) => {
    commit('setUser', null)
    commit('products/setProducts', null, { root: true })

    const currentRouter = router.app.$route
    if (!(currentRouter.meta && currentRouter.meta.authNotRequired)) {
      router.push('/login')
    }
  },

  // ONBOARDING:
  // Once a pin is made, create a new vault
  newVault: async ({ state, commit }, pin) => {
    try {
      const nv = await newVault(state.user.email, pin)

      const dbVault = await new VaultsDB().create(nv)
      await new UsersDB().update({
        id: state.user.id,
        vaultId: dbVault.id
      })
      commit('vaults/setVaultInfo', dbVault, { root: true })
      console.log("Created vault", dbVault.id)
    } catch (e) {
      console.log('Error creating new vault: ', e)
    }
  }
}
