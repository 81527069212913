import {
  randomBytes,
  createCipheriv,
  createDecipheriv,
  pbkdf2
} from 'crypto-browserify'

const algo = 'aes256'

const deriveKey = (pin, salt) => {
  return new Promise((resolve, reject) => {
    pbkdf2(pin, salt, 500, 32, 'SHA-256', (err, dek) => {
      if (err) {
        reject(err)
      }

      resolve(dek)
    })
  })
}

export const newVault = (email, pin) => {
  const vaultContents = JSON.stringify({ email })
  const keySalt = randomBytes(32)
  const vaultSalt = randomBytes(16)

  return deriveKey(pin, keySalt).then(derivedKey => {
    const cipher = createCipheriv(algo, derivedKey, vaultSalt)
    let encrypted = cipher.update(vaultContents, 'utf8', 'hex')
    encrypted += cipher.final('hex')

    return {
      vaultBlob: encrypted,
      keySalt: keySalt.toString('hex'),
      vaultSalt: vaultSalt.toString('hex')
    }
  })
}

export const encryptVault = (blob, keySalt, vaultSalt, pin) => {
  return deriveKey(pin, keySalt).then(derivedKey => {
    const cipher = createCipheriv(algo, derivedKey, vaultSalt)
    let encrypted = cipher.update(JSON.stringify(blob), 'utf8', 'hex')
    encrypted += cipher.final('hex')

    return encrypted
  })
}

export const decrypt = (encrypted, secretKey, vaultSalt) => {
  const decipher = createDecipheriv(
    algo,
    secretKey,
    vaultSalt
  )
  const decrypted = Buffer.concat([
    decipher.update(Buffer.from(encrypted, 'hex')),
    decipher.final()
  ])
  return JSON.parse(decrypted.toString())
}

export const openVault = (encryptedVault, keySalt, vaultSalt, pin) => {
  return deriveKey(pin, keySalt).then(key => {
    return decrypt(encryptedVault, key, vaultSalt)
  })
}

/*
window.newVault = newVault
window.pbkdf2 = pbkdf2
window.decrypt = decrypt
window.randomBytes = randomBytes
window.openVault = openVault
window.decrypt = openVault
*/
