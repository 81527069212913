import VaultsDB from '@/firebase/vaults-db'
import { openVault, encryptVault } from '@/misc/crypto'

export default {
  namespaced: true,
  state: {
    id: null,
    vaultBlob: null,
    keySalt: '',
    vaultSalt: '',
    loading: 'loading', // loading | done | error
    pin: '',
    pinResult: true
  },
  mutations: {
    setVaultInfo: (state, value) => {
      state.id = value.id
      state.vaultBlob = value.vaultBlob
      state.keySalt = Buffer.from(value.keySalt, 'hex')
      state.vaultSalt = Buffer.from(value.vaultSalt, 'hex')
    },
    updateBlob: (state, value) => {
      state.vaultBlob = value
    },
    setNullVault: state => {
      state.loading = 'done'
      state.vaultBlob = null
    },
    loading: (state, value) => {
      state.loading = value
    },
    setPin: (state, value) => {
      state.pin = value
    },
    pinResult: (state, value) => {
      state.pinResult = value
    }
  },
  actions: {
    // Try to unlock vault
    tryPin: async ({ state, commit }, pin) => {
      try {
        const contents = await openVault(state.vaultBlob, state.keySalt, state.vaultSalt, pin)
        commit('setPin', pin);
        commit('passwords/setPasswords', contents.passwords || [], {root: true})
      } catch (e) {
        commit('pinResult', false);
        console.log('Error opening vault: ', e)
      }
    },
    resetPin: ({ commit }) => {
      commit('setPin', '');
      commit('pinResult', true);
    },
    updateMyVault: async ({ state, commit }, newPasswords) => {
      if (!state.pin.length) {
        throw new Error('Must collect pin before updating vault')
      }

      const vault = await openVault(
        state.vaultBlob,
        state.keySalt,
        state.vaultSalt,
        state.pin
      )

      vault.passwords = newPasswords
      const newVaultBlob = await encryptVault(
        vault,
        state.keySalt,
        state.vaultSalt,
        state.pin
      )

      await new VaultsDB().update({
        id: state.id,
        vaultBlob: newVaultBlob
      })
      commit('updateBlob', newVaultBlob)
    },
    fetchVault: async ({ commit }, id) => {
      commit('loading', 'loading')
      try {
        const vault = await new VaultsDB().read(id)
        commit('setVaultInfo', vault)
        commit('loading', 'done')
      } catch (e) {
        commit('loading', 'error')
      }
    },
    setNullVault: async ({ commit }) => {
      commit('setNullVault')
    }
  },
  getters: {
    hasVault: state => state.vaultBlob !== null,
    vaultLoading: state => state.loading
  }
}
