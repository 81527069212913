import { find } from 'lodash'

export default {
  namespaced: true,
  state: {
    passwords: []
  },
  mutations: {
    setPasswords: (state, vaultPasswords) => {
      state.passwords = vaultPasswords
    }
  },
  actions: {
    upsertPassword: ({ state, commit, dispatch }, newPass) => {
      const foundPass = find(state.passwords, { name: newPass.name })
      const passWithoutOld = state.passwords.filter(p => {
        return p.name !== newPass.name
      })

      const previousPasswords = foundPass
        ? foundPass.previousPasswords.concat([foundPass.password])
        : []
      const newPasswords = passWithoutOld.concat([
        {
          ...newPass,
          previousPasswords
        }
      ])

      commit('setPasswords', newPasswords)
      dispatch('vaults/updateMyVault', newPasswords, { root: true })
    }
  },
  getters: {
    allPasswords: state => state.passwords,
    numPasswords: state => (state.passwords ? state.passwords.length : 'no'),
    categoryPasswords: state => {
      return category => {
        return state.passwords.filter((p) => p.category.toLowerCase() === category.toLowerCase())
      }
    }
  }
}
