import '@fortawesome/fontawesome-free/css/all.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#F47D31',
        secondary: '#F5F2ED',
        accent: '#01a9b4',
        error: '#af2d2d',
        black: '#2E1708'
      }
    }
  },
  icons: {
    iconfont: 'fa'
  }
})
