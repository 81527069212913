<template>
  <v-container fill-height fluid>
    <div v-if="vaultLoading === 'loading'" class="page-loader">
      <v-img src="@/assets/img/preloader_puff.svg"></v-img>
    </div>
    <div v-if="vaultLoading === 'error'">
      <p>There was an error loading your vault. Please retry later</p>
    </div>
    <v-container v-if="hasVault" class="pa-0">
      <v-container class="d-flex justify-center pb-6">
        <img alt="Password House" class="logo" src="@/assets/img/logo.svg" />
      </v-container>
      <transition name="slide-up" appear>
        <v-container class="cards">
          <NavTile
            title="View passwords"
            icon="fa-lock"
            :to="{ path: '/pin?next=passwords' }"
          />
          <NavTile
            title="Add password"
            icon="fa-plus-circle"
            :to="{ path: '/pin?next=add' }"
          />
        </v-container>
      </transition>
    </v-container>
    <transition name="slide-up" appear>
      <v-container v-if="!hasVault" class="pa-0" fill-height fluid>
        <v-container>
          <v-container class="d-flex justify-center">
            <img
              alt="Password House"
              class="logo"
              src="@/assets/img/logo.svg"
            />
          </v-container>
          <v-row align="center" justify="center" class="mb-12">
            <v-col class="text-center">
              <v-container class="pb-8">
                <h1 class="text-h6 black--text text-center">
                  Securely store your passwords and share them with your family.
                </h1>
              </v-container>
              <v-btn
                data-test="login-btn"
                class="login-btn mb-4"
                color="black white--text"
                x-large
                block
                depressed
                to="/onboarding"
              >
                Create my vault
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-container>
    </transition>
  </v-container>
</template>

<script>
import NavTile from '@/components/NavTile.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    NavTile
  },
  computed: {
    ...mapGetters('vaults', ['vaultLoading', 'hasVault'])
  }
}
</script>

<style lang="sass" scoped></style>
