import firebase from 'firebase/app'
import 'firebase/auth'

// The configuration below is not sensitive data. You can serenely add your config here
const config = {
  apiKey: 'AIzaSyCgudAzOmQ3EdJubyEez9zrNPTvHVvidn8',
  authDomain: 'password-house.firebaseapp.com',
  projectId: 'password-house',
  storageBucket: 'password-house.appspot.com',
  messagingSenderId: '733226233533',
  appId: '1:733226233533:web:601da72675c2ebd8e851d4',
  measurementId: 'G-9TJ05KC7X6'
}

firebase.initializeApp(config)
