<template>
  <router-link class="password-category text--primary" :to="to">
    <h3>
      <v-icon :color="color || 'primary'" large>{{ icon }}</v-icon>
      {{ title }}
    </h3>
  </router-link>
</template>

<script>
export default {
  name: 'NavTile',
  props: ['title', 'subtitle', 'icon', 'to', 'color']
}
</script>

<style lang="scss" scoped>
.password-category {
  display: block;
  background: white;
  border-radius: 4px;
  padding: 0.85em 1.2em 0.88em 0.55em;
  margin-bottom: 0.75em;
  font-size: 24px;
  text-decoration: none;

  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.06), 0px 8px 12px 1px rgba(0, 0, 0, 0.04), 0px 5px 22px 4px rgba(0, 0, 0, 0.02);
  &:hover {
    box-shadow: 0px 7px 10px 0px rgba(0, 0, 0, 0.07), 0px 9px 18px 4px rgba(0, 0, 0, 0.04), 0px 5px 22px 4px rgba(0, 0, 0, 0.02);
  }

  .v-icon {
    width: 65px;
    padding-right: 8px;
    text-align: center;
    transform: translateY(-2px)
  }
}
</style>
