<template>
  <v-app>
    <nav-bar></nav-bar>
    <v-main>
      <router-view />
    </v-main>
    <new-content-available-toastr
      v-if="newContentAvailable"
      class="new-content-available-toastr"
      :refreshing-app="refreshingApp"
      @refresh="serviceWorkerSkipWaiting"
    ></new-content-available-toastr>
    <apple-add-to-home-screen-modal
      v-if="showAddToHomeScreenModalForApple"
      class="apple-add-to-home-screen-modal"
      @close="closeAddToHomeScreenModalForApple(false)"
    >
    </apple-add-to-home-screen-modal>
  </v-app>
</template>
<script>
import NavBar from '@/components/NavBar'
import NewContentAvailableToastr from '@/components/NewContentAvailableToastr'
import AppleAddToHomeScreenModal from '@/components/AppleAddToHomeScreenModal'
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  components: { NavBar, NewContentAvailableToastr, AppleAddToHomeScreenModal },
  computed: {
    ...mapGetters('app', ['newContentAvailable']),
    ...mapState('app', ['showAddToHomeScreenModalForApple', 'refreshingApp'])
  },
  methods: mapActions('app', [
    'closeAddToHomeScreenModalForApple',
    'serviceWorkerSkipWaiting'
  ])
}
</script>

<style lang="sass">
.container
  max-width: 600px !important

.v-application .text-h4
  font-weight: 600

.page-loader
  top: 0
  left: 0
  height: 100vh
  width: 100vw
  position: fixed
  z-index: 999999
  background: #F5F2ED
  .v-image
    width: 42px
    height: 42px
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
    margin: auto

.logo
  max-width: 200px

.v-application .v-btn,
.v-application .v-btn.v-size--large
  font-size: 18px
  letter-spacing: 0.02em
  font-weight: 600
  text-transform: none

.v-application .v-sheet.v-card:not(.v-sheet--outlined)
  border: 1px solid #ddd
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.06), 0px 12px 17px 2px rgba(0, 0, 0, 0.04), 0px 5px 22px 4px rgba(0, 0, 0, 0.02)
  &:hover
    box-shadow: 0px 7px 8px -2px rgba(0, 0, 0, 0.07), 0px 12px 17px 4px rgba(0, 0, 0, 0.04), 0px 5px 22px 4px rgba(0, 0, 0, 0.02)
  .v-card__text
    font-size: 0.95rem !important
    color: #333 !important

.v-text-field, .v-textarea
  .v-input__slot
    background: #fff !important
  label
    font-size: 1.4em
    color: rgba(0, 0, 0, 0.8)
  input, textarea
    font-size: 1.4em
  .v-text-field__details .v-messages
    font-size: 1.1em

.otp-input
  width: 80px
  height: 80px
  padding: 5px
  margin: 0 5px
  font-size: 40px
  border-radius: 4px
  border: 1px solid rgba(0, 0, 0, 0.3)
  text-align: center
  background: #fff
  &.error
    border: 1px solid red !important
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button
    -webkit-appearance: none
    margin: 0

// TRANSITIONS

.slide-up-enter-active
  transform: translateY(0)
  transition: all .25s ease
  transition-delay: .05s

.slide-up-enter
  transform: translateY(10px)
  opacity: 0

.slide-up-over-enter-active
  transform: translateY(0)
  transition: all .25s ease

.slide-up-over-enter
  transform: translateY(10px)
  opacity: 0

.fade-enter-active, .fade-leave-active
  transition: opacity .2s ease

.fade-enter, .fade-leave-to
  opacity: 0

body, header
  //margin-top: constant(safe-area-inset-top)
  margin-top: env(safe-area-inset-top) !important
  //margin-bottom: constant(safe-area-inset-bottom)
  margin-bottom: env(safe-area-inset-bottom)
</style>
