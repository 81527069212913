<template>
  <div :class="{ offline: !networkOnLine }">
    <v-app-bar
      v-if="
        !['home', 'login', 'check-login', 'onboarding'].includes($route.name)
      "
      color="secondary"
      flat
      app
    >
      <v-btn large icon color="black" @click="$router.go(-1)">
        <v-icon>fa-arrow-left</v-icon>
      </v-btn>

      <v-spacer></v-spacer>

      <div v-if="!networkOnLine" class="nav-item offline-label">Offline</div>
    </v-app-bar>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  computed: {
    ...mapGetters('authentication', ['isUserLoggedIn']),
    ...mapState('authentication', ['user']),
    ...mapState('app', ['networkOnLine'])
  }
}
</script>

<style lang="sass" scoped></style>
